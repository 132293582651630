import * as styles from "./workshopsSection.module.scss"

import React, { useMemo } from "react"

import Circle from "../../atoms/circle/circle"
import Rectangle from "../../atoms/rectangle/rectangle"
import Section from "../../atoms/section/section"
import WorkshopComponent from "../../molecules/workshop-component/workshopComponent"
import { useWindowSize } from "../../../hooks/useWindowSize"

export const WorkshopsSection = ({ deboraImg, RaffaeleImg, simpolImg, anchorRef }) => {
  const speaker1Description =
    "Experience designer, da circa 20 anni progetta siti web e applicazioni mobile. È inoltre autrice del libro “User eXperience Design”"
  const speaker2Description =
    "Graphic designer, è stato uno dei fondatori dell'area Design & Videomaking ed ex vice presidente di JEToP. Ha inoltre lavorato per dare un'identità visiva ai TEDx di Torino."
  const speaker3Description =
    "Agenzia di comunicazione specializzata nella progettazione di campagne marketing, strategie digital e gestione di pagine social."

  const windowSize = useWindowSize()

  const outerCircleRadius = useMemo(() => windowSize.width / 7, [windowSize])
  const innerCircleRadius = useMemo(() => windowSize.width / 8, [windowSize])

  return (
    <Section
      name="formatori"
      backgroundColor="var(--wdd-yellow)"
      anchorRef={anchorRef}
    >
      <h2 className={styles.sectionTitle}>Formatori e Workshop</h2>
      <WorkshopComponent
        speakerName="Debora Bottà"
        speakerJob="Experience designer"
        imageUrl={deboraImg}
        workshopName="Basi teoriche, metodologia e step progettuali"
        textContent={speaker1Description}
        alignment="left"
      />
      <WorkshopComponent
        speakerName="Raffaele Armellino"
        speakerJob="Graphic designer"
        imageUrl={RaffaeleImg}
        workshopName="Funzionamento di Figma e focus pratico"
        textContent={speaker2Description}
        alignment="right"
      >
        <div className={styles.threeAngledRectanglesContainer}>
          <Rectangle
            offset={{
              x: 0,
              y: 20,
            }}
            length={windowSize.width / 4}
            thickness={10}
            color="var(--wdd-cyan)"
            angle={-30}
            animationType="length"
          />
          <Rectangle
            offset={{
              x: 0,
              y: 0,
            }}
            length={windowSize.width / 4}
            thickness={10}
            color="var(--wdd-cyan)"
            angle={-30}
            animationType="length"
          />
          <Rectangle
            offset={{
              x: 0,
              y: -20,
            }}
            length={windowSize.width / 4}
            thickness={10}
            color="var(--wdd-cyan)"
            angle={-30}
            animationType="length"
          />
        </div>
        <div className={styles.circlesContainer}>
          <Circle
            radius={outerCircleRadius}
            borderColor="var(--wdd-purple)"
            borderThickness={5}
          >
            <div style={{ zIndex: 10 }}>
              <Circle
                radius={innerCircleRadius}
                borderColor="var(--wdd-white)"
                borderThickness={5}
              >
                <Rectangle
                  offset={{
                    x: -innerCircleRadius * 0.7 - innerCircleRadius / 8,
                    y: -innerCircleRadius * 0.7,
                  }}
                  length={innerCircleRadius * 1.5}
                  animationType="length"
                  thickness={6}
                  color="var(--wdd-cyan)"
                />
                <Rectangle
                  offset={{
                    x: -innerCircleRadius * 0.7,
                    y: -innerCircleRadius * 0.7 + innerCircleRadius / 15,
                  }}
                  length={innerCircleRadius * 1.5}
                  animationType="length"
                  thickness={6}
                  color="var(--wdd-cyan)"
                />
              </Circle>
            </div>
          </Circle>
        </div>
      </WorkshopComponent>
      <WorkshopComponent
        speakerName="Simpol Agency"
        speakerJob="Agenzia di comunicazione"
        imageUrl={simpolImg}
        workshopName="Brand identity"
        textContent={speaker3Description}
        alignment="left"
      >
        <div className={styles.threeFlatRectanglesContainer}>
          <Rectangle
            offset={{
              x: 0,
              y: 20,
            }}
            length={windowSize.width / 4}
            thickness={10}
            color="var(--wdd-cyan)"
            angle={0}
            animationType="length"
          />
          <Rectangle
            offset={{
              x: 0,
              y: 0,
            }}
            length={windowSize.width / 4}
            thickness={10}
            color="var(--wdd-cyan)"
            angle={0}
            animationType="length"
          />
          <Rectangle
            offset={{
              x: 0,
              y: -20,
            }}
            length={windowSize.width / 4}
            thickness={10}
            color="var(--wdd-cyan)"
            angle={0}
            animationType="length"
          />
        </div>
      </WorkshopComponent>
    </Section>
  )
}

export default WorkshopsSection
