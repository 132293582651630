import React from "react"
import * as styles from "./numbersCollection.module.scss"

import NumberCounter from "../../atoms/number-counter/numberCounter"

export default function NumbersCollection() {
  return (
    <div className={styles.mainContainer}>
      <NumberCounter targetValue={4} label="Formazioni" />
      <NumberCounter targetValue={12} label="Team" isTwoDigit />
      <NumberCounter targetValue={1} label="Sfida" />
    </div>
  )
}
