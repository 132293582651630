import React, { useMemo, memo } from "react"
import * as styles from "./circleDesign.module.scss"

import { useWindowSize } from "../../../hooks/useWindowSize"

import Circle from "../../atoms/circle/circle"
import Rectangle from "../../atoms/rectangle/rectangle"

import cn from "classnames"

const polarToCartesian = (angle, radius) => {
  const radians = (angle * Math.PI) / 180

  const x = radius * Math.cos(-radians)
  const y = radius * Math.sin(-radians)

  return { x, y }
}

const coordinatesCombiner = (...coordinates) => {
  return coordinates.reduce((acc, curr) => {
    return {
      x: acc.x + curr.x,
      y: acc.y + curr.y,
    }
  })
}

const CircleDesign = () => {
  const windowSize = useWindowSize()

  const circleRadius = useMemo(() => {
    const w = windowSize.width

    if (w > 1100) {
      return (w * 0.3) / 2
    }

    if (w > 750) {
      return (w * 0.45) / 2
    }

    if (w > 550) {
      return (w * 0.6) / 2
    }
    return (w * 0.8) / 2
  }, [windowSize])

  // I know this is just circleRadius but it's to make it more readable
  const longThinBaseLength = useMemo(() => circleRadius, [circleRadius])
  const shortThinBaseLength = useMemo(() => circleRadius / 2, [circleRadius])
  const shortChubbyBaseLength = useMemo(() => circleRadius / 2, [circleRadius])
  const longChubbyBaseLength = useMemo(() => circleRadius / 1.7, [circleRadius])

  const chubbyRectangleThickness = useMemo(
    () => circleRadius / 8,
    [circleRadius]
  )

  const thinRectangleThickness = useMemo(
    () => circleRadius / 25,
    [circleRadius]
  )

  const rectanglesData = useMemo(() => {
    return [
      {
        color: "var(--wdd-purple)",
        length: shortChubbyBaseLength,
        thickness: chubbyRectangleThickness,
        offset: polarToCartesian(150, circleRadius),
        animationType: "thickness",
      },
      {
        color: "var(--wdd-purple)",
        length: longChubbyBaseLength,
        thickness: chubbyRectangleThickness,
        offset: polarToCartesian(-60, circleRadius),
        animationType: "thickness",
      },
      {
        color: "var(--wdd-cyan)",
        length: longThinBaseLength,
        thickness: thinRectangleThickness,
        offset: polarToCartesian(60, circleRadius),
        animationType: "length",
      },
      {
        color: "var(--wdd-cyan)",
        length: longThinBaseLength,
        thickness: thinRectangleThickness,
        offset: polarToCartesian(53, circleRadius),
        animationType: "length",
      },
      {
        color: "var(--wdd-cyan)",
        length: shortThinBaseLength,
        thickness: thinRectangleThickness,
        animationType: "length",
        offset: coordinatesCombiner(polarToCartesian(-150, circleRadius), {
          x: circleRadius / 8,
          y: 0,
        }),
      },
      {
        color: "var(--wdd-cyan)",
        length: shortThinBaseLength,
        thickness: thinRectangleThickness,
        animationType: "length",
        offset: coordinatesCombiner(polarToCartesian(-145, circleRadius), {
          x: -circleRadius / 8,
          y: 0,
        }),
      },
    ]
  }, [
    shortChubbyBaseLength,
    chubbyRectangleThickness,
    circleRadius,
    longChubbyBaseLength,
    longThinBaseLength,
    thinRectangleThickness,
    shortThinBaseLength,
  ])

  return (
    <Circle
      radius={circleRadius}
      borderColor={"var(--wdd-purple)"}
      borderThickness={4}
    >
      <div className={styles.textsContainer}>
        <span className={cn(styles.infoText)}>Save the date!</span>
        <span className={cn(styles.dateInfo)}>24.09 - 30.09</span>
        <span className={cn(styles.infoText)}>Politecnico di Torino</span>
      </div>
      {rectanglesData.map((rect, index) => (
        <Rectangle
          key={index}
          color={rect.color}
          length={rect.length}
          thickness={rect.thickness}
          angle={rect.angle}
          offset={rect.offset}
          animationType={rect.animationType}
        />
      ))}
    </Circle>
  )
}

export default memo(CircleDesign)
