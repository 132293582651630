import * as styles from "./title.module.scss"

import React, { memo } from "react"

import PropTypes from "prop-types"

const Title = memo(({ contentTitle }) => {
  return <div className={styles.container}>{contentTitle}</div>
})

Title.propTypes = {
  contentTitle: PropTypes.node,
}
export default Title
