// extracted by mini-css-extract-plugin
export var mainContainer = "workshopComponent-module--mainContainer--3aeN-";
export var row = "workshopComponent-module--row--24htk";
export var rowReverse = "workshopComponent-module--rowReverse--3iRC1";
export var positionRelative = "workshopComponent-module--positionRelative--2tfCn";
export var leftColumn = "workshopComponent-module--leftColumn--2Wm-c";
export var rightColumn = "workshopComponent-module--rightColumn--3QjUx";
export var alignmentStart = "workshopComponent-module--alignmentStart--1XHRh";
export var alignmentEnd = "workshopComponent-module--alignmentEnd--3NPHX";
export var marginLeft = "workshopComponent-module--marginLeft--15qJs";
export var marginRight = "workshopComponent-module--marginRight--1RAMd";
export var borderRight = "workshopComponent-module--borderRight--1LwQP";
export var borderLeft = "workshopComponent-module--borderLeft--3ZXw0";
export var workshopName = "workshopComponent-module--workshopName--3jEY3";
export var speakerName = "workshopComponent-module--speakerName--2-172";
export var circle = "workshopComponent-module--circle--2Q2dp";
export var circleLeft = "workshopComponent-module--circleLeft--1CjYE";
export var circleRight = "workshopComponent-module--circleRight--3TfAZ";
export var speakerNameOverImage = "workshopComponent-module--speakerNameOverImage--2TJck";
export var workshopContent = "workshopComponent-module--workshopContent--3jXz7";
export var speakerJob = "workshopComponent-module--speakerJob--3jkiB";
export var mobHidden = "workshopComponent-module--mobHidden--3WdaN";
export var desktopHidden = "workshopComponent-module--desktopHidden--25Fo2";