import React, { useState, useCallback, useMemo, useDispatch } from "react"
import * as styles from "./navbar.module.scss"
import Button from "../../atoms/button/button"
import Rectangle from "../../atoms/rectangle/rectangle"
import { useWindowSize } from "../../../hooks/useWindowSize"

import ContactSidebar from "../contact-sidebar/contactSidebar"
import cn from "classnames"
import { Link } from "gatsby"

const Navbar = ({ currentSection = "home" }) => {
  const [isContactPanelOpen, setIsContactPanelOpen] = useState(false)
  const windowSize = useWindowSize()

  const isDesktop = useMemo(() => windowSize.width > 910, [windowSize])

  const onContactUsPressed = useCallback(() => {
    setIsContactPanelOpen(!isContactPanelOpen)
  }, [isContactPanelOpen])

  const contactUsOverlayStyles = useMemo(() => {
    return {
      transition: "background-color 0.3s ease-in-out",
      position: "absolute",
      top: 0,
      left: 0,
      height: isContactPanelOpen ? "100vh" : 0,
      width: isContactPanelOpen ? "100vw" : 0,
      backgroundColor: isContactPanelOpen ? "rgba(0,0,0,0.8)" : "transparent",
    }
  }, [isContactPanelOpen])

  const mobMenuButtonStyles = useMemo(() => {
    const rotationAngle = isContactPanelOpen ? 180 : 0
    const rightOffsetPercentage = isContactPanelOpen
      ? isDesktop
        ? 30
        : 85
      : 10

    return {
      transform: `rotate(${rotationAngle}deg)`,
      right: `${rightOffsetPercentage}%`,
      opacity: isDesktop ? (isContactPanelOpen ? 1 : 0) : 1,
    }
  }, [isContactPanelOpen, isDesktop])

  return (
    <div className={styles.nav}>
      <div className={styles.logoAndLinksContainer}>
        <Link className={styles.logoText} to="/">
          WDD
          <br /> 2021
        </Link>
        <div className={styles.linksContainer}>
          <a
            className={cn(
              styles.menuLink,
              currentSection === "home" ? styles.active : undefined
            )}
            href="#home"
          >
            Home
          </a>
          <a
            className={cn(
              styles.menuLink,
              currentSection === "about" ? styles.active : undefined
            )}
            href="#about"
          >
            About
          </a>
          <a
            className={cn(
              styles.menuLink,
              currentSection === "workshops" ? styles.active : undefined
            )}
            href="#formatori"
          >
            Formatori
          </a>
          <a
            className={cn(
              styles.menuLink,
              currentSection === "sponsors" ? styles.active : undefined
            )}
            href="#sponsor"
          >
            Sponsor
          </a>
        </div>
      </div>
      <div>
        <div className={styles.contact}>
          <Button variant={"light-magenta"} onClick={onContactUsPressed}>
            CONTATTACI
          </Button>
        </div>
        <div style={contactUsOverlayStyles} />
        <div style={mobMenuButtonStyles} className={styles.arrow}>
          <Button variant="text" onClick={onContactUsPressed}>
            <Rectangle
              offset={{ x: 0, y: isContactPanelOpen ? 0 : 5 }}
              length={15}
              thickness={2}
              angle={45}
              color="var(--wdd-magenta)"
              smoothTransition
            ></Rectangle>
            <Rectangle
              offset={{ x: 0, y: isContactPanelOpen ? 0 : -5 }}
              length={15}
              thickness={2}
              angle={-45}
              color="var(--wdd-magenta)"
              smoothTransition
            ></Rectangle>
          </Button>
        </div>
      </div>

      <ContactSidebar isOpen={isContactPanelOpen} />
    </div>
  )
}

export default Navbar
