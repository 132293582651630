import * as styles from "./aboutSection.module.scss"

import React, { useMemo } from "react"

import Arrow from "../../atoms/arrow/arrow"
import Button from "../../atoms/button/button"
import Circle from "../../atoms/circle/circle"
import MacOsContainer from "../../atoms/macOsContainer/macOsContainer"
import MainSubTitle from "../../atoms/mainSubTitle/mainSubTitle"
import MainTitle from "../../atoms/mainTitle/mainTitle"
import Rectangle from "../../atoms/rectangle/rectangle"
import Section from "../../atoms/section/section"
import cn from "classnames"
import { useWindowSize } from "../../../hooks/useWindowSize"

const AboutSection = ({ anchorRef }) => {
  const windowSize = useWindowSize()

  const quarterWidth = useMemo(
    () => windowSize.width / 4,
    [windowSize],
    [windowSize]
  )

  return (
    <Section
      name="about"
      backgroundColor="var(--wdd-blue)"
      anchorRef={anchorRef}
    >
      <div className={styles.content}>
        <div className={styles.leftColumn}>
          <div className={styles.leftP}>
            <div className={styles.rowTitle}>
              <Arrow></Arrow>
              <div className={styles.title}>
                <MainTitle contentTitle={"About"}></MainTitle>
              </div>
            </div>
            <div className={styles.text}>
              <MainSubTitle>
                <p align="left">
                  Ti mancano pochi passi per iscriverti ai{" "}
                  <b>Web Design Days!</b>
                  <br /> Non perdere l’opportunità di migliorare le tue skills
                  nella <b>UX/UI</b> o di svilupparne di nuove, abbiamo infatti
                  messo a tua disposizione <b>professionisti</b> con anni di
                  esperienza che ti guideranno in due giornate di{" "}
                  <b>
                    formazione, condivisione di esperienze e applicazione dei
                    concetti.
                  </b>{" "}
                  Alla fine delle prime due giornate verrà annunciata la{" "}
                  <b>Challenge Evento</b>, la sfida che metterà i team l’uno
                  contro l’altro per consegnare il miglior progetto possibile.
                  Le premiazioni avverranno l’ultimo giorno dopo una scrupolosa
                  selezione dei progetti da parte del team di <b>JEToP</b>.
                </p>
              </MainSubTitle>
            </div>
            <div className={styles.rectangle}></div>
          </div>

          <div className={styles.hidenC}>
            <div className={styles.outerCircle2}>
              <Circle
                radius={120}
                borderColor={
                  windowSize.width < 911 ? "transparent" : "var(--wdd-yellow)"
                }
                borderThickness={3}
              >
                <div className={styles.innerCircle2}>
                  <Circle
                    radius={100}
                    borderColor={"var(--wdd-purple)"}
                    borderThickness={2}
                  ></Circle>
                </div>
              </Circle>
            </div>
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.paragraph}>
            <div className={styles.box}>
              <Circle
                Circle
                radius={120}
                borderColor={"var(--wdd-yellow)"}
                borderThickness={3}
              ></Circle>
              <Rectangle
                offset={{ x: 100, y: -65 }}
                color={"var(--wdd-magenta)"}
                length={160}
                thickness={13}
                animationType="length"
              ></Rectangle>
              <Rectangle
                offset={{ x: 80, y: -85 }}
                color={"var(--wdd-magenta)"}
                length={160}
                thickness={13}
                animationType="length"
              ></Rectangle>
              <Rectangle
                offset={{ x: -100, y: -30 }}
                color={"var(--wdd-magenta)"}
                length={130}
                thickness={36}
                animationType="thickness"
              ></Rectangle>
            </div>
            <div className={styles.macOS}>
              <MacOsContainer buttonsColor={"magenta"} buttonsPosition={"end"}>
                <div className={styles.pad}>
                  <div className={styles.rowTitle}>
                    <Arrow></Arrow>
                    <div className={styles.title}>
                      <MainTitle contentTitle={"How to apply"}></MainTitle>
                    </div>
                  </div>

                  <div className={styles.text}>
                    <MainSubTitle>
                      <p align="left">Per partecipare ti serve:</p>
                      <ul className={styles.list} align="left">
                        <li>
                          <b>Un team da 4 persone</b>
                        </li>
                        <li>
                          <b>Green Pass </b>
                        </li>
                        <li>
                          <b>Conoscenze teoriche e pratiche base di UX/UI</b>
                        </li>
                        <li>
                          <b>
                            Tanta voglia di migliorare e sviluppare skills da
                            pro designer!
                          </b>
                        </li>
                      </ul>
                      <p align="left">
                        Non perdere l’occasione per metterti in gioco! Hai tempo{" "}
                        <b>fino al 20 Settembre</b>.
                      </p>
                    </MainSubTitle>
                  </div>
                </div>
              </MacOsContainer>
            </div>
            <div className={styles.threerRectangles}>
              <Rectangle
                offset={{ x: -50, y: 0 }}
                color={"var(--wdd-magenta)"}
                length={100}
                thickness={13}
                animationType="length"
              ></Rectangle>
              <Rectangle
                offset={{ x: -30, y: 20 }}
                color={"var(--wdd-magenta)"}
                length={100}
                thickness={13}
                animationType="length"
              ></Rectangle>
              <Rectangle
                offset={{ x: -70, y: 40 }}
                color={"var(--wdd-magenta)"}
                length={100}
                thickness={13}
                animationType="length"
              ></Rectangle>
            </div>
          </div>
        </div>
        <div className={styles.paragraphT}>
          <div className={styles.rowTitle}>
            <Arrow></Arrow>
            <div className={styles.title}>
              <MainTitle contentTitle={"How to apply"}></MainTitle>
            </div>
          </div>

          <div className={styles.text}>
            <MainSubTitle>
              <p align="left">Per partecipare ti serve:</p>
              <ul className={styles.list} align="left">
                <li>
                  <b>Un team da 4 persone</b>
                </li>
                <li>
                  <b>Green Pass </b>
                </li>
                <li>
                  <b>Conoscenze teoriche e pratiche base di UX/UI</b>
                </li>
                <li>
                  <b>
                    Tanta voglia di migliorare e sviluppare skills da pro
                    designer!
                  </b>
                </li>
              </ul>
              <p align="left">
                Non perdere l’occasione per metterti in gioco! Hai tempo{" "}
                <b>fino al 20 Settembre</b>.
              </p>
            </MainSubTitle>
          </div>
        </div>
      </div>
      <div className={styles.rectangles}>
        <Rectangle
          offset={{ x: -10, y: 0 }}
          color={"var(--wdd-magenta)"}
          length={400}
          thickness={10}
          animationType="length"
        ></Rectangle>
        <Rectangle
          offset={{ x: -10, y: 15 }}
          color={"var(--wdd-magenta)"}
          length={550}
          thickness={10}
          animationType="length"
        ></Rectangle>
        <Rectangle
          offset={{ x: -10, y: 30 }}
          color={"var(--wdd-magenta)"}
          length={500}
          thickness={10}
          animationType="length"
        ></Rectangle>
      </div>
      <div className={styles.boxB}>
        <div className={styles.twoRectanglesLeft}>
          <Rectangle
            offset={{ x: -quarterWidth * 1.1, y: 0 }}
            color={"var(--wdd-magenta)"}
            length={quarterWidth * 2}
            thickness={10}
            animationType="length"
          ></Rectangle>
          <Rectangle
            offset={{ x: -quarterWidth * 1.1, y: 20 }}
            color={"var(--wdd-magenta)"}
            length={quarterWidth * 2}
            thickness={10}
            animationType="length"
          ></Rectangle>
        </div>
        <div className={cn(styles.outerCircle)}>
          <Circle
            radius={80}
            borderColor={
              windowSize.width < 836 ? "transparent" : "var(--wdd-yellow)"
            }
            borderThickness={3}
          >
            <div className={styles.innerCircle}>
              <Circle
                radius={72}
                borderColor={"var(--wdd-purple)"}
                borderThickness={2}
              >
                <div>
                  <div className={styles.text}>
                    <MainSubTitle>Iscriviti ora!</MainSubTitle>
                  </div>

                  <div className={cn(styles.button, styles.subscribe)}>
                    <Button variant={"light-magenta"} bold={true}>
                      <a
                        href="https://www.eventbrite.it/e/biglietti-web-design-days-2021-169872570431"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        eventbrite
                      </a>
                    </Button>
                  </div>
                </div>
              </Circle>
            </div>
          </Circle>
        </div>
        <div className={styles.twoRectanglesRight}>
          <Rectangle
            offset={{ x: quarterWidth * 1.1, y: 0 }}
            color={"var(--wdd-magenta)"}
            length={quarterWidth * 2}
            thickness={10}
            animationType="length"
          ></Rectangle>
          <Rectangle
            offset={{ x: quarterWidth * 1.1, y: 20 }}
            color={"var(--wdd-magenta)"}
            length={quarterWidth * 2}
            thickness={10}
            animationType="length"
          ></Rectangle>
        </div>
      </div>
    </Section>
  )
}

export default AboutSection
