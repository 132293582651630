/* eslint-disable prettier/prettier */

import * as styles from "./footer.module.scss"
import { StaticImage } from "gatsby-plugin-image"

import Button from "../../atoms/button/button"
import Circle from "../../atoms/circle/circle"
import MainSubTitle from "../../atoms/mainSubTitle/mainSubTitle"
import React from "react"
import Title from "../../atoms/title/title"
import cn from "classnames"
import jetop from "../../../images/logo_jetop.png"
import { useWindowSize } from "../../../hooks/useWindowSize"

const Footer = ({ anchorRef }) => {
  const windowSize = useWindowSize()
  return (
    <div className={styles.footer}>
      <a id="sponsor" />
      <div className={styles.leftColumn}>
        <div>
          <div ref={anchorRef} className={styles.title}>
            <Title contentTitle={"Main Sponsor"}></Title>
          </div>
          <div className={styles.row}>
            <div className={styles.logo}>
              <StaticImage src="../../../images/sponsors/pepita.png" />
            </div>
            <div className={styles.logo}>
              <StaticImage src="../../../images/sponsors/mystery_house.png" />
            </div>
            <div className={styles.logo}></div>
          </div>
        </div>

        <div>
          <div className={styles.title}>
            <Title contentTitle={"In collaborazione con"}></Title>
          </div>
          <div className={styles.row}>
            <div className={styles.logo}>
              <StaticImage src="../../../images/collaborators/polito_logo.png" />
            </div>
            <div className={styles.logo}>
              <StaticImage src="../../../images/collaborators/caffe_design.png" />
            </div>
            <div className={styles.logo}>
              <StaticImage src="../../../images/collaborators/circolo_del_design.png" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rightColumn}>
        <div>
          <div className={styles.text}>
            <Title contentTitle="Powered by"></Title>
          </div>
          <img className={styles.img} src={jetop} />
        </div>
        <MainSubTitle>
          <p className={cn(styles.text, styles.knowJetopBetter)}>
            Vuoi conoscere <br /> meglio la realtà <br /> di JEToP?
          </p>
        </MainSubTitle>
        <div>
          <div className={cn(styles.outerCircle)}>
            <Circle
              radius={63}
              borderColor={windowSize.width < 911 ? "transparent" : "white"}
              borderThickness={5}
            >
              <div className={styles.innerCircle}>
                <Circle
                  radius={54}
                  borderColor={"var(--wdd-yellow)"}
                  borderThickness={3}
                >
                  <a
                    href="https://www.jetop.com"
                    target="_blank"
                    rel="noreferrer"
                    className={cn(styles.button, "cursor")}
                  >
                    <Button variant={"light-purple"} bold={true}>
                      Visita il sito!
                    </Button>
                  </a>
                </Circle>
              </div>
            </Circle>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
