import React from "react"
import Lottie from "react-lottie"

import { useWindowSize } from "../../../hooks/useWindowSize"
import Rectangle from "../../atoms/rectangle/rectangle"

import CircleDesign from "../../molecules/circle-design-home/circleDesign"
import NumbersCollection from "../../molecules/numbers-collection/numbersCollection"

import * as styles from "./homeSection.module.scss"
import * as animationData from "../../../images/loop.json"

const HomeSection = ({ anchorRef }) => {
  const windowSize = useWindowSize()

  const sectionStyle = {
    height: "calc(100vh - var(--wdd-header-height))",
    width: "100%",
    padding: "5%",
    backgroundColor: "var(--wdd-yellow)",
    marginTop: "var(--wdd-header-height)",
  }

  const defaultAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <div style={sectionStyle}>
      <a id="home" className={styles.anchor} />
      <div className={styles.mainContainer}>
        <div ref={anchorRef}>
          <h1 className={styles.eventName}>WebDesignDays</h1>
          <h4 className={styles.eventSubtitle}>UX/UI Design Contest</h4>
        </div>
        <div className={styles.graphicsContainer}>
          <div className={styles.lottieAnimation}>
            <Lottie
              isClickToPauseDisabled
              options={defaultAnimationOptions}
              width={windowSize.width / 3}
            />
          </div>
          <div className={styles.threeMobLinesContainer}>
            <Rectangle
              color="var(--wdd-cyan)"
              angle={-30}
              thickness={8}
              length={150}
              offset={{ x: -windowSize.width / 2, y: 17 }}
              animationType="length"
            />
            <Rectangle
              color="var(--wdd-cyan)"
              angle={-30}
              thickness={8}
              length={200}
              offset={{ x: -windowSize.width / 2, y: 0 }}
              animationType="length"
            />
            <Rectangle
              color="var(--wdd-cyan)"
              angle={-30}
              thickness={8}
              length={150}
              offset={{ x: -windowSize.width / 2, y: -17 }}
              animationType="length"
            />
          </div>
          <div className={styles.circleDesignContainer}>
            <CircleDesign />
          </div>
        </div>
        <NumbersCollection />
      </div>
    </div>
  )
}

export default HomeSection
