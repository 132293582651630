import React, { useRef, useMemo } from "react"

import AboutSection from "../components/organisms/about-section/aboutSection"
import HomeSection from "../components/organisms/home-section/homeSection"
import Layout from "../components/layout"
import Seo from "../components/seo"
import WorkshopsSection from "../components/organisms/workshops-section/workshopsSection"
import { getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Navbar from "../components/organisms/navbar/navbar"
import Footer from "../components/organisms/footer/footer"

import { useElementIsVisible } from "../hooks/useIsElementVisible"

const IndexPage = ({ data }) => {
  const simpol = getImage(data.allFile.nodes.find((n) => n.name === "Simpol"))
  const debora = getImage(data.allFile.nodes.find((n) => n.name === "Debora"))
  const raffaele = getImage(
    data.allFile.nodes.find((n) => n.name === "Raffaele_Armellino")
  )

  const homeSectionRef = useRef()
  const aboutSectionRef = useRef()
  const workshopsSectionRef = useRef()
  const sponsorsSectionRef = useRef()

  const isHomeSectionVisible = useElementIsVisible(homeSectionRef)
  const isAboutSectionVisible = useElementIsVisible(aboutSectionRef, "200px")
  const isWorkshopsSectionVisible = useElementIsVisible(
    workshopsSectionRef,
    "200px"
  )
  const isSponsorsSectionVisible = useElementIsVisible(sponsorsSectionRef)

  const currentSection = useMemo(() => {
    if (isHomeSectionVisible) return "home"
    if (isAboutSectionVisible) return "about"
    if (isWorkshopsSectionVisible) return "workshops"
    if (isSponsorsSectionVisible) return "sponsors"
    return "null"
  }, [
    isHomeSectionVisible,
    isAboutSectionVisible,
    isWorkshopsSectionVisible,
    isSponsorsSectionVisible,
  ])

  return (
    <Layout>
      <Seo title="Web Design Days 2021" />
      <Navbar currentSection={currentSection} />
      <HomeSection anchorRef={homeSectionRef} />
      <AboutSection anchorRef={aboutSectionRef} />
      <WorkshopsSection
        simpolImg={simpol}
        deboraImg={debora}
        RaffaeleImg={raffaele}
        anchorRef={workshopsSectionRef}
      />
      <Footer anchorRef={sponsorsSectionRef} />
    </Layout>
  )
}

IndexPage.propTypes = {}

export default IndexPage

export const personPlaceholder = graphql`
  {
    allFile(filter: { relativeDirectory: { eq: "speakers" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData
        }
        name
      }
    }
  }
`
