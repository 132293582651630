import React, { useEffect, useState, useRef } from "react"
import * as styles from "./numberCounter.module.scss"
import PropTypes from "prop-types"

import cn from "classnames"

const NumberCounter = ({ label, targetValue, isTwoDigit = false }) => {
  // If is two digit, pick numbers between 10 and 99, otherwise pick numbers between 0 and 9
  const scaleFactor = isTwoDigit ? 89 : 10
  const offsetFactor = isTwoDigit ? 9 : 0

  const [value, setValue] = useState(
    Math.floor(Math.random() * scaleFactor + offsetFactor)
  )

  const valuesCounter = useRef(0)

  useEffect(() => {
    const timer = setInterval(() => {
      if (valuesCounter.current >= 50) {
        clearInterval(timer)
        setValue(targetValue)
      } else {
        setValue(Math.floor(Math.random() * scaleFactor + offsetFactor))
        valuesCounter.current += 1
      }
    }, 25)

    return () => clearInterval(timer)
  }, [])

  return (
    <div className={styles.mainContainer}>
      <h2 className={cn(styles.content, styles.number)}>{value}</h2>
      <h2 className={cn(styles.content, styles.label)}>{label}</h2>
    </div>
  )
}

NumberCounter.propTypes = {
  label: PropTypes.string.isRequired,
  targetValue: PropTypes.number.isRequired,
  isTwoDigit: PropTypes.bool,
}

export default NumberCounter
