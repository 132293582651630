import * as styles from "./workshopComponent.module.scss"

import React, { useMemo } from "react"

import Circle from "../../atoms/circle/circle"
import { GatsbyImage } from "gatsby-plugin-image"
import MacOsContainer from "../../atoms/macOsContainer/macOsContainer"
import PropTypes from "prop-types"
import Rectangle from "../../atoms/rectangle/rectangle"
import cn from "classnames"
import { useWindowSize } from "../../../hooks/useWindowSize"

export const WorkshopComponent = ({
  speakerName,
  speakerJob,
  workshopName,
  imageUrl,
  textContent,
  alignment = "left",
  children,
}) => {
  const windowSize = useWindowSize()

  const circleRadius = useMemo(() => windowSize.width / 4, [windowSize])
  const thinRectLength = useMemo(
    () => (alignment === "left" ? circleRadius * 2.5 : circleRadius * 2),
    [circleRadius, alignment]
  )
  const thinRectThickness = useMemo(() => circleRadius / 20, [circleRadius])
  const thinRectXOffset = useMemo(
    () => (alignment === "left" ? circleRadius : -circleRadius),
    [alignment, circleRadius]
  )
  const thinRectYOffset = useMemo(
    () => (alignment === "left" ? circleRadius / 7 : circleRadius),
    [alignment, circleRadius]
  )

  const fatRectangleLenght = useMemo(() => circleRadius / 2, [circleRadius])
  const fatRectangleThickness = useMemo(() => circleRadius / 8, [circleRadius])
  const fatRectangleXOffset = useMemo(
    () => (alignment === "left" ? -circleRadius * 0.7 : circleRadius * 0.7),
    [alignment, circleRadius]
  )
  const fatRectangleYOffset = useMemo(() => -circleRadius * 0.7, [circleRadius])

  return (
    <div
      className={cn(
        styles.mainContainer,
        alignment === "left" ? styles.row : styles.rowReverse
      )}
    >
      {children}
      <div
        className={cn(
          styles.leftColumn,
          alignment === "left" ? styles.alignmentStart : styles.alignmentEnd,
          alignment === "left" ? styles.marginRight : styles.marginLeft,
          alignment === "left" ? styles.borderRight : styles.borderLeft
        )}
      >
        <h4 className={styles.speakerNameOverImage}>{speakerName}</h4>
        <MacOsContainer
          buttonsColor="purple"
          buttonsPosition={alignment === "left" ? "start" : "end"}
        >
          <GatsbyImage image={imageUrl} alt="Speaker" />
        </MacOsContainer>
        <h6 className={styles.speakerJob}>{speakerJob}</h6>
      </div>
      <div
        className={cn(
          styles.rightColumn,
          alignment === "left" ? styles.alignmentStart : styles.alignmentEnd
        )}
      >
        <div
          className={cn(
            styles.mobHidden,
            alignment === "left" ? styles.alignmentStart : styles.alignmentEnd
          )}
        >
          <h1 className={styles.workshopName}>{workshopName}</h1>
          <h2 className={styles.speakerName}>{speakerName}</h2>
          <h6 className={styles.workshopContent}>{textContent}</h6>
        </div>
        <div className={cn(styles.circleDesignContainer, styles.desktopHidden)}>
          <div
            className={cn(
              styles.circle,
              alignment === "left" ? styles.circleLeft : styles.circleRight
            )}
          >
            <Circle
              radius={circleRadius}
              borderColor="var(--wdd-purple)"
              borderThickness={3}
            >
              <Rectangle
                offset={{
                  x: thinRectXOffset,
                  y: thinRectYOffset - circleRadius / 8,
                }}
                length={thinRectLength}
                thickness={thinRectThickness}
                color="var(--wdd-cyan)"
                angle={-30}
                animationType="length"
              />
              <Rectangle
                offset={{
                  x: thinRectXOffset,
                  y: thinRectYOffset,
                }}
                length={thinRectLength}
                thickness={thinRectThickness}
                color="var(--wdd-cyan)"
                angle={-30}
                animationType="length"
              />
              <Rectangle
                offset={{
                  x: thinRectXOffset,
                  y: thinRectYOffset + circleRadius / 8,
                }}
                length={thinRectLength}
                thickness={thinRectThickness}
                color="var(--wdd-cyan)"
                angle={-30}
                animationType="length"
              />
              <Rectangle
                offset={{
                  x: fatRectangleXOffset,
                  y: fatRectangleYOffset,
                }}
                length={fatRectangleLenght}
                thickness={fatRectangleThickness}
                color="var(--wdd-purple)"
                angle={0}
                animationType="thickness"
              />
              {alignment === "right" && (
                <Rectangle
                  offset={{
                    x: fatRectangleXOffset - circleRadius / 5,
                    y: fatRectangleYOffset - circleRadius / 5,
                  }}
                  length={fatRectangleLenght}
                  thickness={fatRectangleThickness}
                  color="var(--wdd-purple)"
                  angle={0}
                  animationType="thickness"
                />
              )}
            </Circle>
          </div>
          <h1 className={styles.workshopName}>{workshopName}</h1>
        </div>
      </div>
    </div>
  )
}

WorkshopComponent.propTypes = {
  speakerName: PropTypes.string.isRequired,
  workshopName: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired,
  speakerJob: PropTypes.string.isRequired,
  children: PropTypes.node,
  alignment: PropTypes.string,
}

export default WorkshopComponent
