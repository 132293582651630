// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--2ki_y";
export var leftColumn = "footer-module--leftColumn--1H7gY";
export var title = "footer-module--title--X84WY";
export var text = "footer-module--text--3Pm9f";
export var logo = "footer-module--logo--1DlmT";
export var img = "footer-module--img--16MFb";
export var rightColumn = "footer-module--rightColumn--1ZtQu";
export var subTitle = "footer-module--subTitle--jsmze";
export var row = "footer-module--row--2nVbf";
export var innerCircle = "footer-module--innerCircle--36BJf";
export var outerCircle = "footer-module--outerCircle--2ZuDK";
export var button = "footer-module--button--3o1qh";
export var knowJetopBetter = "footer-module--knowJetopBetter--2OtKs";