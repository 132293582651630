// extracted by mini-css-extract-plugin
export var about = "aboutSection-module--about--2kDRr";
export var content = "aboutSection-module--content--QgPcl";
export var rowTitle = "aboutSection-module--rowTitle--f5jt6";
export var title = "aboutSection-module--title--25CaZ";
export var text = "aboutSection-module--text--35mhL";
export var p = "aboutSection-module--p--2_j_4";
export var outerCircle = "aboutSection-module--outerCircle--nNN5z";
export var outerCircle2 = "aboutSection-module--outerCircle2--1a_e3";
export var button = "aboutSection-module--button--2rzju";
export var subscribe = "aboutSection-module--subscribe--1LEJc";
export var img = "aboutSection-module--img--MDN2c";
export var leftColumn = "aboutSection-module--leftColumn--2djMA";
export var rightColumn = "aboutSection-module--rightColumn--2qW5r";
export var rectangle = "aboutSection-module--rectangle--20BTG";
export var hidenC = "aboutSection-module--hidenC--RUdtO";
export var paragraph = "aboutSection-module--paragraph--34GHx";
export var box = "aboutSection-module--box--r0xjN";
export var macOS = "aboutSection-module--macOS--3j9Oz";
export var macOsContainerModuleTopBar301lZ = "aboutSection-module--macOsContainer-module--topBar--301lZ--1V25f";
export var pad = "aboutSection-module--pad--3z5qk";
export var rectangles = "aboutSection-module--rectangles--3FLNw";
export var list = "aboutSection-module--list--2rS0G";
export var threerRectangles = "aboutSection-module--threerRectangles--256vV";
export var leftP = "aboutSection-module--leftP--1T7Hs";
export var paragraphT = "aboutSection-module--paragraphT--1KCmX";
export var twoRectanglesLeft = "aboutSection-module--twoRectanglesLeft--L-8k9";
export var twoRectanglesRight = "aboutSection-module--twoRectanglesRight--3gDlw";
export var boxB = "aboutSection-module--boxB--2SbMX";