// extracted by mini-css-extract-plugin
export var nav = "navbar-module--nav--rGIcA";
export var logoAndLinksContainer = "navbar-module--logoAndLinksContainer--18djf";
export var logoText = "navbar-module--logoText--3dsop";
export var menuLink = "navbar-module--menuLink--2jHcP";
export var active = "navbar-module--active--3kDla";
export var contact = "navbar-module--contact--PfTHM";
export var arrow = "navbar-module--arrow--2-bbm";
export var arrowOpen = "navbar-module--arrowOpen--s5k0t";
export var divM = "navbar-module--divM--2Zq0-";
export var linksContainer = "navbar-module--linksContainer--1MJHW";
export var contactButton = "navbar-module--contactButton--3sGen";