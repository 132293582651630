import React from "react"
import * as styles from "./section.module.scss"

import PropTypes from "prop-types"

export const Section = ({
  children,
  name,
  backgroundColor,
  isFirst = false,
  anchorRef,
}) => {
  const sectionStyle = {
    minHeight: "calc(100vh - var(--wdd-header-height))",
    overflow: "hidden",
    width: "100%",
    padding: "5%",
    backgroundColor: backgroundColor,
    marginTop: isFirst ? "var(--wdd-header-height)" : 0,
  }

  return (
    <>
      <a ref={anchorRef} id={name} className={styles.anchor} />
      <div style={sectionStyle}>{children}</div>
    </>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  isFirst: PropTypes.bool,
}

export default Section
