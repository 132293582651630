// extracted by mini-css-extract-plugin
export var firstSection = "homeSection-module--firstSection--39Osj";
export var anchor = "homeSection-module--anchor--2RO5S";
export var mainContainer = "homeSection-module--mainContainer--3TnEK";
export var graphicsContainer = "homeSection-module--graphicsContainer--3GL2p";
export var eventName = "homeSection-module--eventName--1Dh-x";
export var eventSubtitle = "homeSection-module--eventSubtitle--3YV3l";
export var lottieAnimation = "homeSection-module--lottieAnimation--2hZKd";
export var threeMobLinesContainer = "homeSection-module--threeMobLinesContainer--2ntKu";
export var circleDesignContainer = "homeSection-module--circleDesignContainer--5kQZf";
export var numbersContainer = "homeSection-module--numbersContainer--wigsP";