import * as styles from "./contactSidebar.module.scss"

import React, { useMemo } from "react"

import Button from "../../atoms/button/button"
import MainSubTitle from "../../atoms/mainSubTitle/mainSubTitle"
import MainTitle from "../../atoms/mainTitle/mainTitle"
import PropTypes from "prop-types"
import behance from "../../../images/behance.svg"
import facebook from "../../../images/facebook.svg"
import instagram from "../../../images/instagram.svg"
import linkedin from "../../../images/linkedin.svg"

const ContactSidebar = ({ isOpen }) => {
  const animation = useMemo(() => {
    return {
      transform: isOpen ? "translateX(0)" : "translateX(100%)",
    }
  }, [isOpen])

  return (
    <div className={styles.sidebar} style={animation}>
      <div className={styles.title}>
        <MainTitle contentTitle={"Contattaci"}></MainTitle>
      </div>
      <div className={styles.text}>
        <MainSubTitle>
          Hai qualche dubbio? <br /> Compila il form qua sotto
        </MainSubTitle>
      </div>
      <form
        name="Contact Form"
        method="POST"
        data-netlify="true"
        action="/#home"
      >
        <input type="hidden" name="form-name" value="Contact Form" />
        <div className={styles.text}>
          <MainSubTitle>Nome e cognome</MainSubTitle>
          <input
            type="text"
            name="name"
            className={styles.input}
            required
          ></input>
        </div>

        <div className={styles.text}>
          <MainSubTitle>E-Mail</MainSubTitle>
          <input
            type="email"
            name="email"
            className={styles.input}
            required
          ></input>
        </div>
        <div className={styles.text}>
          <MainSubTitle>Mandaci un messaggio</MainSubTitle>
          <textarea
            name="message"
            className={styles.inputMessage}
            required
          ></textarea>
        </div>
        <Button
          variant={"light-purple"}
          type="submit"
          className={styles.button}
        >
          INVIA
        </Button>
      </form>
      <div className={styles.title}>
        <MainTitle contentTitle={"Follow Us"}></MainTitle>
      </div>
      <div className={styles.imgs}>
        <a className={styles.img} href="https://www.instagram.com/jetop_to/">
          <img src={instagram}></img>{" "}
        </a>
        <a
          className={styles.img}
          href="https://www.linkedin.com/company/j-e-to-p-/mycompany/"
        >
          <img src={linkedin}></img>
        </a>
        <a className={styles.img} href="https://www.facebook.com/jetop.torino">
          <img src={facebook}></img>
        </a>
        <a className={styles.img} href="https://www.behance.net/jetop">
          <img src={behance}></img>
        </a>
      </div>
      <div className={styles.text}>
        <MainSubTitle>
          Junior Enterprise Torino Politecnico <br /> Powered by JEToP
          <br /> Copyright © 2021
        </MainSubTitle>
      </div>
    </div>
  )
}

export default ContactSidebar

ContactSidebar.propTypes = {
  isOpen: PropTypes.bool,
}
